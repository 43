<template>
	<span>
		<a v-if="!internal" :href="item[column.value]">{{
			item[column.value]
		}}</a>
		<v-btn :href="`/video/${appointment.id}`" target="_blank" small color="primary">
            {{ roomExists ? 'Go To Video' : 'Start Video' }}
        </v-btn>
	</span>
</template>

<script>
export default {
	name: "LinkCell",
	props: ["item", "column"],
	computed: {
		appointment() {
			return this.$store.state.appointments.data[this.item.appointment_id];
		},
		internal() {
			return this.appointment.video_medium == "internal";
		},
        roomExists(){
            return this.appointment.video_room !== null;
        }
	},
};
</script>
